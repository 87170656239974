.App {
  display: grid;
  text-align: center;
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.sub-container{
  display: grid;
  row-gap: 3px;
}
.sub-container>button{
  height: 40px;
  width: 300px;
}


.sub-container>button a{
  text-decoration: none;
}

.sub-container>img{
  height: 40%;
  justify-self: flex-end;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
}

p{
  padding: 0;
  margin: 0;
}

ul{
  padding: 0;
  margin: 0;
  list-style: none;
}

.Schedule-Component>main>section>div>p{
  grid-row: 2/2;
  grid-column: 1/2;
  justify-self: center;
  color: white;
  padding: 10px;
}

.BackgroundMainColor{
  background-color: #79A27D;
  display: grid;
}

.MainBTN{
  display: grid;
  background-color: #67886A;
  color: white;
  border: #67886A 1px;
  border-radius: 7px;
  /* height: 20px; */
  padding: 10px;
  text-decoration: none;
  text-align: center;
}


.PageHeaderBack{
  display: grid;
  grid-template-columns: 50px 1fr;
  position: sticky;
  top: 0;
  padding: 10px;
  background-color: #79A27D;
  justify-items: center;font: 
  1.3em sans-serif;
  align-items: center;
}


.flex-container{
  display: flex;
  column-gap: 10px;
}


.UnderDevelopment-PageView{
  margin: 20px 40px ;
  text-align: left;

}


.ParishViewPage{
  display: grid;
  background-color: white;

}


.PageHeaderBack h1 {
  margin-left: -50px;
  color: white;
}

.ParishViewPage>main ul{
  display: grid;
}

.ParishViewPage>main ul li{
  display: grid;
  padding: 20px  40px;
  text-align: left;
  row-gap: 20px;
}

.ParishViewPage>main ul li h2{
  font: 2em sans-serif;
  color: #79A27D;
}

.ParishViewPage>main ul li h3, .ParishViewPage>main ul li h4{
  font: 1.3em sans-serif;
}


.OurMinistriesViewPage{
  display: grid;
}


.OurMinistriesViewPage>main{
  padding: 30px;
  text-align: left;

}

.OurMinistriesViewPage>main ul{
  display: grid;
  row-gap: 30px;

}
.OurMinistriesViewPage>main ul li{
  display: grid;
  font: 1.7 sans-serif;
}

.OurMinistriesViewPage>main ul li h2{
  color: #79A27D;
}

.OurMinistriesViewPage>main ul li p{
  font: 1em sans-serif;
  line-height: 25px;
}

.GiftShopPageView{
  text-align: left;
}

.GiftShopPageView>main{
  display: grid;
  row-gap: 30px; 
  padding: 30px;

}

.GiftShopPageView>main h2{
  color: #79A27D;
  font: 1.7em sans-serif;
}

.GiftShopPageView>main p{
  font: 1.3em sans-serif;
  line-height: 30px
}


.FaithFormationViewPage{
  display: grid;
  text-align: left;
}

.FaithFormationViewPage>main{
  display: grid;
  padding: 30px;
  row-gap: 40px;
}

.FaithFormationViewPage>main aside{
  display: flex;
  column-gap: 10px;
  width: 90vw;
  overflow: scroll;
}

.FaithFormationViewPage>main aside img{
  height: 250px;
}

.FaithFormationViewPage>main section{
  display: grid;
  row-gap: 20px;
}

.FaithFormationViewPage h2{
  color: #79A27D;
  font: 2em sans-serif;
}

.FaithFormationViewPage button{
  background-color: #79A27D;
  border: none;
  height: 40px;
  color: white;
  font: 1em sans-serif;
  border-radius: 7px;
}


.SummerEvents{
  color: white;
  font: 1em sans-serif;
  background-color: #79A27D;
}


.SummerEvents>ul{
  color: black;
  display: grid;
  justify-content: center;
  row-gap: 20px;
}


.SummerEvents>ul>li{
  display: grid;
  row-gap: 10px;
  background-color: white;
  width: 290px;
  border-radius: 7px;
  padding: 10px;
  text-align: left;
}


.PhotoGallery{
  background-color: white;
  align-self: center;
 display: grid;
 row-gap: 20px;
  /* max-width: 95em; */
  justify-self: center;
  margin-bottom: 100px;
  margin-top: 100px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.PhotoGallery div picture{
  display: flex;
  column-gap: 10px;
  overflow: scroll;
  width: 95%;
}

.PhotoGallery div picture img{
  width: 70px;
  height: 70px;
}


@media screen and (min-width: 1100px) {
  .FaithFormationViewPage>main aside{
    width: 65em;
  }
  
}

